.PFTD_Wrapper .PFTD_Editor .ck-editor__editable_inline:not(.ck-comment__input *){
    height: 150px !important;  
  
  }
  
  .PFTD_Wrapper .PFTD_Editor{
    width: 700px !important
  }
  
  .create_PFTD_wrapper.custom_ck_PFTD{
    height: 517px;
  }
  